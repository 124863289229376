<template lang="pug">
.media-manager-upload
  form(v-on:submit.prevent="submit")
    LoaderOverlay(:loading="loading")
      .form-group
        label(for="upload-file") File
        .file-input
          label.btn.btn-default.btn-file(v-bind:style="fileButtonStyles")
            i(:class="fileIconClasses")
            input#upload-file(type="file" ref="fileInput" @change="fileChosen($event.target.files[0])")

            .upload-buttons
              span.btn.btn-default
                i.fa.fa-desktop
              GooglePickerButton.btn.btn-default(@download-started="loading = true" @input="fileChosen")
                i.fa.fa-google

          span.filename.text-muted
            | {{ filename }}

      .form-group
        SqueueField(v-model="squeueId")

      .form-group
        SectionField(v-model="sectionId")

      .form-group
        label(for="upload-credit") Credit
        input#upload-credit.form-control(type="text" v-model="credit")

      .form-group
        label(for="upload-cutline") Cutline
        textarea#upload-cutline.form-control(type="text" v-model="cutline")

      .buttons
        button.btn.btn-primary.btn-block(type="submit")
          i.fa.fa-cloud-upload
          |
          |
          | {{ this.isNew ? 'Upload' : 'Update' }}
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'

  import GooglePickerButton from '../../../components/shared/google_picker_button.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import SectionField from './section_field.vue'
  import SqueueField from '../../../components/shared/squeue_field.vue'

  export default {
    components: {
      GooglePickerButton,
      LoaderOverlay,
      SectionField,
      SqueueField
    },

    props: {
      medium: {
        type: Object,
        default() { return {} }
      }
    },

    data() {
      return {
        fileBeingDragged: false,
        loading: false
      };
    },

    computed: {
      ...mapGetters('medium', [
        'isImage',
        'bestUrl'
      ]),
      ...sync('medium', [
        'id',
        'url',
        'file',
        'filename',
        'imageBase64',
        'squeueId',
        'sectionId',
        'cutline',
        'credit'
      ]),

      fileButtonStyles() {
        if(!this.bestUrl) return {}

        return { backgroundImage: `url('${this.bestUrl}')` }
      },

      fileIconClasses() {
        if (this.fileBeingDragged) {
          return 'fa fa-cloud-upload';
        } else if (this.isImage) {
          return 'fa fa-file-image-o';
        } else {
          return 'fa fa-file';
        }
      },

      isNew() {
        return !this.id
      }
    },

    mounted() {
      document.addEventListener('dragenter', e => {
        this.fileBeingDragged =  true;
      });
    },

    methods: {
      fileChosen(file) {
        if(!file) return

        this.loading = true
        this.$store.dispatch('medium/processFile', { file: file }).then(() => {
          this.loading = false
        })
      },

      submit() {
        this.loading = true

        const action = this.isNew ? this.create : this.update

        action().then(() => {
          this.loading = false
          this.$emit('save')
        })
      },

      params() {
        const file = this.file || this.$refs.fileInput.files[0]
        const { squeueId, sectionId, credit, cutline } = this

        return { file, squeueId, sectionId, credit, cutline }
      },

      create() {
        return this.$store.dispatch('medium/create', this.params())
      },

      update() {
        const { id } = this

        return this.$store.dispatch('medium/update', {
          id, ...this.params()
        })
      }
    }
  }
</script>

<style lang="scss">
  .media-manager-upload {
    .file-input {
      position: relative;

      .upload-buttons {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 100%;
        text-align: right;

        .btn {
          margin-left: 5px;
        }
      }
    }
  }
</style>
