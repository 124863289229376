<template lang="pug">
table.table.table-striped.table-hover.content-list
  thead
    tr
      th(v-if="showCol('color')")
      th(v-if="showCol('checkbox') && !currentUser.role_is_limited")
        CheckBox(v-model="checked")
      th(v-if="showCol('id')")
        SortLink(v-model="sort" column="id" text="ID")
      th(v-if="showCol('pub_date')")
        SortLink(v-model="sort" column="pub_date" text="Pub date")
      th(v-if="showCol('slug')")
        SortLink(v-model="sort" column="slug" text="Slug")
      th(v-if="showCol('name')")
        SortLink(v-model="sort" column="headline" text="Headline")
      th(v-if="showCol('sections')")
        | Print sections
      th(v-if="showCol('web_categories')")
        | Web sections
      th(v-if="showCol('words')")
        SortLink(v-model="sort" column="words" text="Words / Inches")
      th(v-if="showCol('media')")
        SortLink(v-model="sort" column="media_count" text="Attachments")
      th(v-if="showCol('exported')")
        SortLink(v-model="sort" column="exported" text="Web Export")
      th(v-if="showCol('squeue')")
        SortLink(v-model="sort" column="squeue_title" text="Status")
      th.actions(v-if="showCol('actions')")

  tbody
    StoryRow(
      v-for="story in stories"
      :story="story"
      :columns="columns"
      :key="story.id"
      v-on="$listeners"
    )
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'
  import CheckBox from './check_box.vue'
  import SortLink from './sort_link.vue'
  import StoryRow from './story_row.vue'
  import { sortParamsForSearch } from '../../../store/shared/sorting'

  const COLUMNS = [
    'color',
    'checkbox',
    'id',
    'pub_date',
    'name',
    'slug',
    'sections',
    'web_categories',
    'words',
    'media',
    'exported',
    'squeue',
    'actions',
    'locker'
  ]
  const WATCHED_PROPERTIES = [...COLUMNS, 'updated_at']

  export { COLUMNS, WATCHED_PROPERTIES }

  export default {
    components: {
      CheckBox,
      SortLink,
      StoryRow
    },

    props: {
      columns: {
        type: Array,
        default: () => COLUMNS
      }
    },

    computed: {
      stories: sync('stories/stories'),
      text_cont: sync('storySearch/search@text_cont'),
      sort_column: sync('storySearch/search@sort_column'),
      sort_direction: sync('storySearch/search@sort_direction'),
      currentUser: sync('currentUser/currentUser'),
      ...mapGetters('storyBatchOptions', { batchOptions: 'options' }),

      checked: {
        get() {
          const { selectedStoryIds } = this.batchOptions;
          return this.stories.every(
            story => selectedStoryIds.includes(story.id)
          );
        },
        set(val) {
          const mutation = `storyBatchOptions/${val ? 'CHECK' : 'UNCHECK'}`;

          this.stories.forEach(story => {
            this.$store.commit(mutation, story.id);
          });
        }
      },

      sort: {
        get() {
          // Sometimes because of differnt sorting options for fulltext search, we can't use
          // the preferred sort params. So we have sortParamsForSearch to figure that out.
          const { sort_column, sort_direction } = sortParamsForSearch(this)

          return { column: sort_column, direction: sort_direction }
        },
        set(val) {
          this.sort_column = val.column;
          this.sort_direction = val.direction;

          this.$emit('sort', val);
        }
      }
    },

    methods: {
      showCol(column) {
        return this.columns.includes(column)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content-list {
    th {
      font-weight: 700 !important;
    }

    td.actions>.btn-group {
      min-width: 45px;
    }
  }
</style>
