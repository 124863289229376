<template lang="pug">
.clearfix
  template(v-if="readOnly")
    input(type="hidden" :value="inputValue")
    h4(v-if="label") {{ label }}
    .panel.panel-default
      .panel-body(v-html="inputValue" :style="{height: height + 20 + 'px'}")
      .panel-footer.text-right(v-if="filename || !perFieldFileMode")
        i.fa.fa-pencil
        template(v-if="filename")
          |
          | Edit {{ filename }} to make changes
        template(v-else)
          |
          | Edit the content file linked to this story to change this field
  template(v-else)
    RichtextEditor(:show-counts="showCounts" :value="inputValue" @input="inputHandler" :label="label" :height="height")
  .row
    .col-sm-6
      slot
    .col-sm-6(v-if="perFieldFileMode")
      .btn-group.pull-right
        button.btn.btn-default.btn-sm(type="button" :class="{ active: editHereActive }" v-on:click="editWysiygHere()")
          i.fa.fa-pencil-square-o
          |
          | Edit Here
        label.btn.btn-default.btn-sm.pull-left(type="button" :class="{ active: editFileActive }" v-on:click="editFile()")
          i.fa.fa-file-text-o
          |
          | Edit File
          input(type="file" ref="fileInput" v-on:change="fileChosen($event)" style="display: none")
</template>

<script>
  import FileContentLinkedInput from '../../mixins/file_content_linked_input';
  import RichtextEditor from './richtext_editor.vue'

  export default {
    mixins: [FileContentLinkedInput],

    components: {
      RichtextEditor
    },

    props: {
      label: {
        type: String
      },
      height: {
        default: 400
      },
      showCounts: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      editWysiygHere() {
        // Set the value to nil here so tha if we select the same file again,
        // it will trigger the onchange event
        this.$refs.fileInput.value = null;

        // Call the default editHere() function from the FileContentLinkedInput
        // mixin
        this.editHere();
      },

      contentFileSuccessHandler(data) {
        this.fileContent = data.html_content;
        this.inputValue = data.html_content;
        this.useFileContent = true;
      },

      inputHandler(value) {
        // If it's read-only and we shouldn't emit any content changes.
        if(!this.readOnly) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>

<style scoped>
  /*
    FIXME: this style block seems to be causing a `Cannot read property 'extend'
    of undefined` error until `webpack-dev-server` is restarted
  */

  input[type=file] {
    display: none;
  }

  .panel-body {
    overflow: auto;
  }
</style>
