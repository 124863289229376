<template lang="pug">
table.table.table-striped
  thead
    tr
      th.text-center Favorite
      th Title
      th Query
      th Default
      th(v-if="hotQueues") Hot queue
      th.actions
  tbody
    Row(
      v-for="storySavedSearch in storySavedSearches"
      :story-saved-search="storySavedSearch"
      :key="storySavedSearch.id"
    )
</template>

<script>
  import { sync } from 'vuex-pathify';

  import Row from './row.vue'

  export default {
    components: {
      Row
    },

    computed: {
      storySavedSearches: sync('storySavedSearches/storySavedSearches'),
      hotQueues: sync('settings/hotQueues')
    },

    created() {
      // Get the hotQueues setting
      this.$store.dispatch('settings/fetchOnce');
      // Get the user's default_story_saved_search_id
      this.$store.dispatch('currentUser/fetch');
    }
  }
</script>
