<template lang="pug">
div
  CommunityQPublishModal
  StoryEmailModal

  router-link.btn.btn-info.pull-right(:to="`/issues/${page.issue.id}`")
    i.fa.fa-arrow-left
    |
    | Back
  h1
    | {{ page.title }}
    |
    small {{ page.issue.title }}

  hr

  LoaderOverlay(:loading="loading")
    .row
      .col-md-9
        h2 Stories

        StoryFileDrop
          .alert.alert-info(v-if="stories.length === 0") No stories were found
          StoriesTable(v-else :columns="COLUMNS" @sort="sortStories" @should-refresh="fetchStories()")

          div.text-right
            NewStoryButton

        hr

        .header
          h2 Files

          FileBreadcrumbs(:resource-slug="page.slug")

          .download-all.text-right
            a.btn.btn-default(@click="downloadZip")
              i.fa.fa-download
              |
              | Download All

        FileIndex(:resourceGlobalId="pageGlobalId")

      Sidebar.col-md-3.page
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { GlobalId } from '../../../utils/global_id'
  import { trimEnd } from 'lodash'

  import CommunityQPublishModal from '../../../components/community_q/publish_modal.vue'
  import FileBreadcrumbs from '../../../components/files/breadcrumbs.vue'
  import FileIndex from '../../../components/files/index.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import NewStoryButton from '../../../components/stories/index/new_story_button.vue'
  import Sidebar from './sidebar.vue'
  import StoriesTable from '../../../components/stories/index/stories_table.vue'
  import StoryEmailModal from '../../../components/stories/email_modal.vue'
  import StoryFileDrop from '../../../components/stories/index/story_file_drop.vue'

  const COLUMNS = [
    'color',
    'id',
    'byline',
    'slug',
    'sections',
    'words',
    'media',
    'exported',
    'squeue',
    'actions'
  ];

  export default {
    components: {
      CommunityQPublishModal,
      FileBreadcrumbs,
      FileIndex,
      LoaderOverlay,
      NewStoryButton,
      Sidebar,
      StoriesTable,
      StoryEmailModal,
      StoryFileDrop
    },

    props: {
      issueId: {
        required: true,
        type: Number
      },
      pageId: {
        required: true,
        type: Number
      }
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      page: sync('page/page'),
      stories: sync('stories/stories'),

      pageGlobalId() {
        return new GlobalId({ model: 'Page', id: this.pageId }).toString()
      }
    },

    async created() {
      this.COLUMNS = COLUMNS
    },

    async mounted() {
      await this.$store.dispatch('page/fetch', { pageId: this.pageId })
      await this.$store.dispatch('squeues/fetchOnce')
      await this.fetchStories()

      this.loading = false
    },

    methods: {
      fetchStories(queryAddons = {}) {
        return this.$store.dispatch(
          'stories/fetch',
          {
            query: { pages_id_eq: this.pageId, ...queryAddons },
            // We don't want to really do pagination at this level
            page: 1, prePage: 1000
          }
        )
      },

      sortStories({ column, direction }) {
        this.fetchStories({ s: `${column} ${direction}` })
      },

      downloadZip() {
        const path = this.$store.getters['files/resourceFilesPath']()

        window.location = `${trimEnd(path, '/')}.zip`
      }
    }
  }
</script>

<style scoped>
  .header {
    display: flex;
  }

  .header h2 {
    margin-right: 0.5em;
  }

  .header .download-all {
    flex-grow: 1;
  }
</style>
