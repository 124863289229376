<template lang="pug">
.richtext-editor
  .row(v-if="label || showCounts")
    .col-sm-6
      label.control-label
        h4(v-if="label") {{ label }}
    .col-sm-6.counts.text-right(v-if="showCounts")
      span.label-group
        span.label.label-default Words
        span.label.label-info {{ wordCount }}
      | &nbsp; &nbsp;
      span.label-group
        span.label.label-default Inches
        span.label.label-info {{ inchCount }}&quot;
  textarea(ref="editor" :height="height" v-text="value")
</template>

<script>
  require('summernote')
  require('summernote-cleaner')
  require('summernote/dist/summernote.css')
  require('summernote-ext-specialchars/summernote-ext-specialchars')

  const BAD_TAGS = ['applet', 'script', 'title', 'meta', 'link', 'head']
  const BAD_ATTRIBUTES = ['contenteditable', 'style']

  module.exports = {
    props: {
      label: {
        type: String
      },
      height: {
        default: 400
      },
      showCounts: {
        default: false
      },
      type: {
        default: 'default'
      },
      value: {
        default: ''
      }
    },

    data() {
      return {
        content: '',
        wordCount: 0,
        inchCount: 0
      }
    },

    computed: {
      wordsPerInch() {
        return this.$store.getters['settings/wordsPerInch']
      }
    },

    watch: {
      value() {
        // Prevent update loops that move the cursor to the start of the content
        if(this.value != this.richtextContent()) {
          this.setRichtextContext(this.value)
        }
      },

      content() {
        this.$emit('input', this.richtextContent())
        this.updateCounts()
      }
    },

    mounted() {
      jQuery(this.$refs.editor).summernote({
        height: this.height,
        toolbar: this.toolbarOptions(),
        cleaner: {
          action: 'paste',
          keepHtml: true,
          keepTagContents: [], // remove tags and keep the contents
          badTags: BAD_TAGS,
          badAttributes: BAD_ATTRIBUTES,
          notTimeOut: 850, // time before status message is hidden in miliseconds
        },
        callbacks: {
          onInit: () => {
            if(this.value) {
              this.setRichtextContext(this.value)
              this.updateCounts()
            }
          },
          onChange: this.updateContent,
          onBlur: this.updateContent
        }
      })

      this.$store.dispatch('settings/fetchOnce')
    },

    methods: {
      richtextContent() {
        return jQuery(this.$refs.editor).summernote('code')
      },

      setRichtextContext(content) {
        jQuery(this.$refs.editor).summernote('code', content)
      },

      updateContent() {
        this.content = this.richtextContent()
      },

      updateCounts() {
        this.wordCount = this.content.
          replace(/&nbsp;/g, '').
          replace(/<[^>]*>/g, '').
          replace(/\s+/g, ' ').
          replace(/&\w+;/g ,'X').
          trim().split(' ').length

        this.inchCount = Math.round(this.wordCount / this.wordsPerInch)
      },

      toolbarOptions() {
        switch (this.type) {
          case 'simple':
            return [
              ['state', ['undo', 'redo']],
              ['font', ['bold', 'italic', 'underline', 'superscript', 'style', 'clear']],
              ['insert', ['link']],
              ['advanced', ['codeview', 'help']]
            ]
          default:
            return [
              ['state', ['undo', 'redo']],
              ['font', ['bold', 'italic', 'underline', 'superscript', 'style', 'clear']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['insert', ['link', 'table', 'specialCharacter']],
              ['advanced', ['codeview', 'help']]
            ]
        }
      }
    }
  }
</script>
