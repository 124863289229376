<template lang="pug">
.form-group(v-if="currentUser.can_set_squeue")
  label.control-label(v-if="showLabel")
    | Status
    span.required(v-if="required") *
  select.form-control(:value="value" @input="updateSqueueId", :disabled="disabled")
    option(v-if="!required" :value="null")
    option(v-for="squeue in squeues" :value="squeue.id") {{ squeue.title }}
</template>

<script>
  import { sync } from 'vuex-pathify';

  export default {
    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      },
      required: {
        type: Boolean,
        default() { return false }
      },
      showLabel: {
        type: Boolean,
        default() { return true }
      },
      disabled: {
        type: Boolean,
        default() { return false }
      }
    },

    computed: {
      currentUser: sync('currentUser/currentUser'),

      squeues() {
        const squeues = this.$store.getters['squeues/enabled']

        return squeues
      }
    },

    async created() {
      await this.$store.dispatch('squeues/fetchOnce')
    },

    methods: {
      updateSqueueId({ target: { value }}) {
        const numValue = Number(value)
        const id = numValue === 0 ? null : numValue

        this.$emit('input', id)
      }
    }
  }
</script>
