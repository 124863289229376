<template lang="pug">
.custom-folder.input-group
  .input-group-addon
    i.fa.fa-hdd-o
  Input(v-model="path" @blur="updateCustomFolder()")
  span.input-group-btn
    button.btn.btn-danger(type="button" @click="deleteCustomFolder()")
      i.fa.fa-times
</template>

<script>
  import { sync } from 'vuex-pathify'
  import Input from '../../shared/input.vue'

  export default {
    components: {
      Input
    },

    props: {
      customFolderId: {
        type: Number,
        required: false
      }
    },

    data() {
      // This is a weird hack to make vue-pathify work with variable expansion.
      // See https://github.com/davestewart/vuex-pathify/issues/103.
      return { storeRootAttr: 'customFolders' }
    },

    computed: {
      id: sync('customFolders/:storeRootAttr@:customFolderId.id'),
      path: sync('customFolders/:storeRootAttr@:customFolderId.path')
    },

    methods: {
      updateCustomFolder() {
        this.$store.dispatch('customFolders/update', this.id)
      },

      deleteCustomFolder() {
        this.$store.dispatch('customFolders/delete', this.id)
      }
    }
  }
</script>
