<template lang="pug">
ol.breadcrumb
  li(:class="{ active: atRoot }")
    template(v-if="atRoot")
      | {{ resourceSlug }}
    template(v-else)
      a(@click="goToIndex(0)") {{ resourceSlug }}

  li(v-for="(part, index) in currentPathParts" :key="part" :class="{ active: current(index) }")
    template(v-if="current(index)")
      | {{ part }}
    template(v-else)
      a(@click="goToIndex(index + 1)") {{ part }}
</template>

<script>
  import { get, sync } from 'vuex-pathify'

  export default {
    props: {
      resourceSlug: {
        type: String,
        required: true
      }
    },

    computed: {
      currentPathParts: sync('files/currentPathParts'),

      atRoot() {
        return this.currentPathParts.length === 0
      },

      lastIndex() {
        return this.currentPathParts.length - 1
      }
    },

    methods: {
      current(index) {
        return index === this.lastIndex
      },

      goToIndex(index) {
        let newPathParts = this.currentPathParts.slice(0, index)

        this.$store.dispatch('files/goTo', newPathParts)
      }
    }
  }
</script>

 <style scoped>
  a {
    cursor: pointer;
  }
</style>
