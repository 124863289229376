<template lang="pug">
tr
  td.text-center
    a(@click="toggleFavorite()")
      i.fa.fa-lg(:class="{'fa-star': favorite, 'fa-star-o': !favorite}")
  td {{ title }}
  td
    a(:href="query" :title="query") {{ truncatedQuery }}
  td
    a(@click="toggleDefault()")
      i.fa(:class="{ 'fa-check-square-o': userDefault, 'fa-square-o': !userDefault }")
  td(v-if="hotQueues")
    a(@click="toggleHotQueue()")
      i.fa(:class="{ 'fa-check-square-o': hotQueue, 'fa-square-o': !hotQueue }")
  td
    a.btn.btn-danger.btn-xs(@click="destroy()")
      i.fa.fa-trash-o
</template>

<script>
  import { sync } from 'vuex-pathify';

  const maxQueryLength = 120;

  export default {
    props: {
      storySavedSearch: {
        requried: true,
        type: Object
      }
    },

    computed: {
      hotQueues: sync('settings/hotQueues'),
      id() { return this.storySavedSearch.id },
      title() { return this.storySavedSearch.title },
      query() { return this.storySavedSearch.query },
      truncatedQuery() {
        const query = this.storySavedSearch.simplified_query;

        if(query.length < maxQueryLength) {
          return query;
        } else {
          return query.slice(0, maxQueryLength) + '...';
        }
      },
      hotQueue() { return this.storySavedSearch.hot_queue },
      favorite() { return this.storySavedSearch.favorite },
      // Avoid "default" in template becaues it's a reserved word
      defaultStorySavedSearchId: sync('currentUser/currentUser@default_story_saved_search_id'),
      userDefault() {
        return this.id == this.defaultStorySavedSearchId;
      },
    },

    methods: {
      toggleFavorite() {
        this.$store.dispatch('storySavedSearches/toggleFavorite', this.id);
      },

      toggleDefault() {
        const defaultId = this.userDefault ? null : this.id;

        this.$store.dispatch(
          'currentUser/update',
          { default_story_saved_search_id: defaultId }
        );
      },

      toggleHotQueue() {
        this.$store.dispatch('storySavedSearches/toggleHotQueue', this.id);
      },

      destroy() {
        if(confirm(`Delete ${this.title}?`)) {
          this.$store.dispatch('storySavedSearches/destroy', this.id);
        }
      }
    }
  }
</script>
