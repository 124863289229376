<template lang="pug">
.custom-folders
  CustomFolder(v-for="folder, id) in customFolders" :custom-folder-id="parseInt(id)" :key="id")
  .text-right
    button.btn.btn-link(type="button" @click="createNew()")
      i.fa.fa-plus
      |
      | Add file storage location
</template>

<script>
  import { mapState } from 'vuex'
  import CustomFolder from './custom_folder.vue'

  export default {
    components: {
      CustomFolder
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    computed: {
      ...mapState('customFolders', {
        customFolders: state => state.customFolders
      })
    },

    mounted() {
      this.$store.dispatch('customFolders/fetchForStory', this.storyId)
    },

    methods: {
      createNew() {
        this.$store.dispatch('customFolders/create', this.storyId)
      }
    }
  }
</script>
