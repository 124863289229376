<template lang="pug">
.form-group
  template(v-if="anyExportFormats")
    Label(text="Export Formats")
    DropdownCheckboxes.block(label="Export formats" v-model="exportFormatIds" :options="allExportFormatsAsObject")
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'

  import DropdownCheckboxes from '../../../components/dropdown_checkboxes.vue'
  import Label from '../../../components/shared/label.vue'

  export default {
    components: {
      DropdownCheckboxes,
      Label
    },

    computed: {
      ...mapGetters(
        'exportFormats', {
          allExportFormatsAsObject: 'allAsObject',
          defaultFormats: 'allAutoExports'
        }
      ),

      exportFormatIds: sync('story/export_format_ids'),

      anyExportFormats() {
        return Object.keys(this.allExportFormatsAsObject).length > 0
      }
    },

    async mounted() {
      await this.$store.dispatch('exportFormats/fetchOnce')
    }
  }
</script>

<style>
  .default-format {
    width: 50%;
    float: left;
    font-size: 1.2em;
    margin: 2px 0;
    opacity: 0.8;
  }
</style>
