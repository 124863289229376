<template lang="pug">
article.vue-page-story-show-root
  CommunityQPublishModal
  StoryEmailModal

  nav.story-header.container(data-spy="affix" data-offset-top="95")
    .row
      .col-md-6
        h1 {{ name }}

      .col-md-6.text-right
        .btn-group
          a.btn.btn-default.btn-info(@click="goBack()")
            i.fa.fa-arrow-left
            |
            | Back

          EditButton(title="Edit" :story-id="storyId")
          MoreButton(:story-id="storyId" :story-name="name")

          template(v-if="!spiked")
            button.btn.btn-danger(type="button" @click.prevent="spikeStory")
              i.fa.fa-trash

    .row
      .col-md-6
        p.server-path
          i.fa.fa-hdd-o
          |
          |
          a(v-if="open_folder_url" :href="open_folder_url")
            | {{ content_path }}
          span(v-else)
            | {{ content_path }}
      .col-md-6.text-right
        p.updated-at(title='Last modified')
          i.fa.fa-clock-o
          |
          | {{ lastUpdated }}

  .row
    .col-md-7
      LabelledValue(label="Slug" :value="slug")

      LabelledValue(label="Overline" :value="overline")

      LabelledValue(label="Headline" :value="headline")

      LabelledValue(label="Subhead" :value="drop_head")

      LabelledValue(label="Byline" :value="byline" :html="true")

      LabelledValue(label="Content" :value="content" :multi-line="true" :html="true")

      LabelledValue(label="Tag line" :value="tag" :multi-line="true" :html="true")

      LabelledValue(label="Credit" :value="credits")

      LabelledValue(label="Endnote" :value="endnote")

      Publication(v-for="storyPub in Object.values(storyPublications)" :key="storyPub.id" :story-publication="storyPub")

      .panel.panel-default(v-show="showCommunityQ")
        .panel-heading
          h4
            | Web
            |
            |
            i.fa.fa-angle-down
        .panel-body
          LabelledValue(label="Headline" :value="web_head")
          LabelledValue(label="Summary" :value="web_summary" :multi-line="true" :html="true")
          LabelledValue(label="Body" :value="web_body" :multi-line="true" :html="true")
          .row
            .col-md-6
              LabelledValue(label="Status" :value="onlineStatusTitle")
            .col-md-6
              LabelledValue(label="Keywords" :value="keywords")

      .panel.panel-default(v-show="showInfobox")
        .panel-heading
          h4
            | Infobox
            |
            |
            i.fa.fa-angle-down
        .panel-body
          LabelledValue(label="Headline" :value="infobox_headline")
          LabelledValue(label="Text" :value="infobox_text" :multi-line="true" :html="true")

      .panel.panel-default(v-show="showPullQuote")
        .panel-heading
          h4
            | Pull Quotes
            |
            |
            i.fa.fa-angle-down
        .panel-body
          LabelledValue(label="Headline" :value="pullQuote.headline")
          LabelledValue(label="Quote" :value="pullQuote.quote" :multi-line="true")
          LabelledValue(label="Attribution" :value="pullQuote.attribution")

    #media.col-md-5
      h4 Attachments
      .story-medium(v-for="storyMedium in storyMedia" :key="storyMedium.id")
        .media(v-if="!storyMedium.use_as_content_for")
          .media-left
            a.image(v-if="storyMedium.medium.url" :href="storyMedium.medium.url" target="_blank")
              img(v-if="isImage(storyMedium)" :src="storyMedium.medium.small_thumb_url" width="100")
              .well.non-image-file(v-else)
                i.fa.fa-file

          .media-body
            LabelledValue(label="Credit" :value="storyMedium.medium.credit")
            LabelledValue(label="Cutline" :value="storyMedium.medium.cutline")

            .text-left
              a.media-filename.text-muted(:href="storyMedium.medium.url" v-text="$options.filters.mediaFileName(storyMedium.medium.filename)")
            .text-right
              LabelledCheckbox(label="For print" :value="storyMedium.for_print")
              LabelledCheckbox(label="For web" :value="storyMedium.for_web")

      hr

      .row
        .col-md-6
          LabelledValue(label="Status" :value="squeueTitle")

        .col-md-6
          LabelledValue(label="Pub date" :value="pub_date")

        .col-md-6
          LabelledValue(label="Print sections" :value="sectionTitles.join(', ')")

        .col-md-6
          LabelledValue(label="Owner" :value="userName")

        .col-md-6
          LabelledValue(label="Web categories" :value="webCategoryTitles.join(', ')")

      template(v-if="storyPlacements.length > 0")
        hr

        Label(text="Issues")
        ul.list-group
          Placement(
            v-for="placement in storyPlacements"
            :placement="placement"
            :key="placement.id"
          )

      hr

      Label(text="File storage locations")
      pre(v-for="path in [content_path].concat(customFolderPaths)" :key="path")
        i.fa.fa-hdd-o
        | &nbsp;{{ path }}

      hr

      LabelledValue(label="Notes" :value="notes" :multi-line="true")

      template(v-if="currentUser.show_advert_fields")
        hr

        LabelledCheckbox(label="Twitter feed" :value="twitter_feed")
        LabelledCheckbox(label="Tweet" :value="tweet")
        LabelledCheckbox(label="Google Search Ad" :value="google_search_ad")
        LabelledCheckbox(label="Outbrain Ad" :value="outbrain_ad")

        hr

      div
        LabelledCheckbox(label="Featured" :value="featured")
        LabelledCheckbox(label="Free" :value="ispublic")
        LabelledCheckbox(label="Comments disabled" :value="comments_disabled")
        LabelledCheckbox(label="Freelance" :value="freelance")

        hr

      LabelledValue(label="Export formats" :value="exportFormatNames")
</template>

<script>
  import { sync } from 'vuex-pathify';
  import { mapGetters } from 'vuex';
  import EditButton from '../../../components/stories/actions/edit_button.vue';
  import MoreButton from '../../../components/stories/actions/more_button.vue';
  import Label from '../../../components/shared/label.vue';
  import Value from '../../../components/shared/value.vue';
  import LabelledValue from '../../../components/shared/labelled_value.vue';
  import LabelledCheckbox from '../../../components/shared/labelled_checkbox.vue';
  import Placement from '../../../components/stories/placement/index.vue';
  import Publication from './publication.vue'
  import CommunityQPublishModal from '../../../components/community_q/publish_modal.vue';
  import StoryEmailModal from '../../../components/stories/email_modal.vue'
  import { storyRoute } from '../../../utils/route_helpers.js'
  import { formatDateTime } from '../../../utils/time_helpers.js'

  const IMAGE_CONTENT_TYPE = 'image'

  export default {
    components: {
      EditButton,
      MoreButton,
      Label,
      Value,
      LabelledCheckbox,
      LabelledValue,
      Placement,
      Publication,
      CommunityQPublishModal,
      StoryEmailModal
    },

    props: {
      storyId: {
        type: Number,
        required: true
      },
      issueId: {
        type: Number,
        required: false
      },
      pageId: {
        type: Number,
        required: false
      }
    },

    computed: {
      ...sync('story/*'),
      spiked: sync('story@spiked'),
      currentUser: sync('currentUser/currentUser'),
      publications: sync('publications/publications'),
      storyPublications: sync('storyPublications/storyPublications'),
      storyPlacements: sync('storyPlacements/storyPlacements'),
      pullQuote: sync('pullQuote'),
      ...mapGetters('customFolders', { customFolderPaths: 'allPaths' }),
      ...mapGetters('onlinestatuses', { onlinestatusForId: 'forId' }),
      ...mapGetters('sections', { sectionsForIds: 'forIds' }),
      ...mapGetters('squeues', { squeueForId: 'forId' }),
      ...mapGetters('storyMedia', { storyMedia: 'attachments' }),
      ...mapGetters('users', { userForId: 'forId' }),
      ...mapGetters('webCategories', { webcategoriesForIds: 'forIds' }),
      ...mapGetters(
        'exportFormats', {
          exportFormatsForIds: 'forIds'
      }),

      exportFormatNames() {
        const formats = this.exportFormatsForIds(this.export_format_ids)

        return formats.map(format => format.name).join(', ')
      },

      name() {
        return this.headline ? this.headline : this.slug;
      },

      onlineStatusTitle() {
        if (!this.onlinestatus_id) return null;
        return this.onlinestatusForId(this.onlinestatus_id)?.title
      },

      squeueTitle() {
        return this.squeueForId(this.squeue_id)?.title
      },

      sectionTitles() {
        return this.sectionsForIds(this.section_ids).map(section => section.title)
      },

      userName() {
        return this.userForId(this.user_id)?.name
      },

      webCategoryTitles() {
        return this.webcategoriesForIds(this.web_category_ids).map(webCat => webCat.title)
      },

      showCommunityQ() {
        return !!(
          this.web_head ||
          this.web_summary ||
          this.web_body ||
          this.web_category_ids.length > 0 ||
          this.onlinestatus_id ||
          this.keywords
        );
      },

      showInfobox() {
        return this.infobox_headline || this.infobox_text;
      },

      showPullQuote() {
        const { headline, quote, attribution } = this.pullQuote;

        return headline || quote || attribution;
      },

      lastUpdated() {
        return formatDateTime(this.updated_at);
      }
    },

    async mounted() {
      const id = this.storyId

      await this.$store.dispatch('story/fetch', { id: this.storyId })
      await this.$store.dispatch('story/fetchDependentData')
    },

    methods: {
      isImage({ medium: { content_type } }) {
        return content_type?.split('/')?.[0] === IMAGE_CONTENT_TYPE
      },

      goBack() {
        this.$router.push(storyRoute({
          storyRoute: 'stories',
          issueRoute: 'issue',
          pageRoute: 'issuePage',
          router: this.$router
        }))
      },

      spikeStory() {
        if(confirm(`Sure you want to spike "${this.name}"?`)) {
          this.$store.dispatch('story/spike').then(() => this.goBack())
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .well.non-image-file {
    padding: 0;
  }
</style>
