<template lang="pug">
.form-group
  label.control-label Byline
  vue-simple-suggest.byline(
    v-model="inputValue"
    :list="bylinePresetList"
    :min-length="0"
    :filter-by-query="true"
    :readonly="readOnly"
    :destyled="true"
    :styles="customStyles"
  )

  .row.buttons-row
    .col-sm-6
      slot
    .col-sm-6(v-if="perFieldFileMode")
      .btn-group.pull-right
        button.btn.btn-default.btn-sm(type="button" :class="{ active: !useFileContent }" v-on:click="editHere()")
          i.fa.fa-pencil-square-o
          |
          | Edit Here
        label.btn.btn-default.btn-sm.pull-left(type="button" :class="{ active: useFileContent }" v-on:click="useFileContent = true")
          i.fa.fa-file-text-o
          |
          | Edit File
          input(type="file" v-on:change="fileChosen($event)" style="display: none")
</template>

<script>
  import FileContentLinkedInput from '../../mixins/file_content_linked_input';
  import VueSimpleSuggest from 'vue-simple-suggest/lib';
  import { sync } from 'vuex-pathify';

  export default {
    mixins: [FileContentLinkedInput],

    components: {
      VueSimpleSuggest
    },

    props: {
      contentFor: {
        type: String,
        default: () => 'byline'
      }
    },

    data() {
      return {
        customStyles: {
          vueSimpleSuggest: "position-relative",
          inputWrapper: "",
          defaultInput : "form-control",
          suggestions: "position-absolute list-group z-1000",
          suggestItem: "list-group-item"
        }
      }
    },

    computed: {
      currentUserByline: sync('currentUser/currentUser@byline'),
    },

    mounted() {
      this.$store.dispatch('bylinePresets/fetch');
    },

    methods: {
      bylinePresetList() {
        // If it's read-only, don't suggest changes when they focus on the input
        if(this.readOnly) return []

        return [
          this.currentUserByline,
          ...this.$store.getters['bylinePresets/all']
        ].filter(Boolean); // Remove empty strings
      },

      contentFileSuccessHandler(data) {
        this.fileContent = data.content;
        this.inputValue = data.content;
        this.useFileContent = true;
      }
    }
  }
</script>

<style lang="scss">
  .byline {
    .list-group-item {
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }
</style>
