<template lang="pug">
.story-publication.panel.panel-default
  .panel-heading
    template(v-if="onlyPublication")
      .row.only
        .col-md-6
          h4 {{ publication.name }}
            small publication
        .col-md-6.text-right
          ShowHideButton.btn-sm(v-model="shown")
    template(v-else)
      label.multiple
        CheckboxIcon.check-icon(v-if="!updating" :checked="checked" @click.native="toggle")
        span.loader-wrapper(v-if="updating")
          Loader.loader
        h4 {{ publication.name }}
          small publication


  .panel-body(v-if="checkedOrShown")
    .form-group
      label.control-label Content Type
      CommunityqContentTypeField(
        :publication-id="publication.id"
        v-model="storyPublication.content_type_key"
        v-if="!publication.hide_content_types"
      )
      select.form-control(v-else disabled)
        option(selected) disabled for this publication

    .form-group
      label.control-label Story Template
      CommunityqStoryTemplateField(
        :publication-id="publication.id"
        v-model="storyPublication.story_template_id"
        v-if="!publication.hide_story_templates"
      )
      select.form-control(v-else disabled)
        option(selected) disabled for this publication

    .form-group
      CommunityqSubsitesField(
        :publication-id="publication.id"
        v-model="storyPublication.subsite_town_ids"
      )
</template>

<script>
import CheckboxIcon from '../../shared/checkbox_icon.vue'
import CommunityqContentTypeField from './communityq_content_type_field.vue'
import CommunityqStoryTemplateField from './communityq_story_template_field.vue'
import CommunityqSubsitesField from './communityq_subsites_field.vue'
import Loader from '../../shared/loader.vue'
import ShowHideButton from '../../show_hide_button.vue'

export default {
  components: {
    CheckboxIcon,
    CommunityqContentTypeField,
    CommunityqStoryTemplateField,
    CommunityqSubsitesField,
    Loader,
    ShowHideButton
  },

  props: {
    storyId: {
      type: Number,
      required: true
    },

    publication: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      shown: false,
      updating: false
    }
  },

  computed: {
    storyPublication() {
      return this.$store.getters['storyPublications/byPublicationId'](this.publication.id);
    },

    onlyPublication() {
      return this.$store.getters['publications/publications'].length <= 1;
    },

    checked() {
      return !!this.storyPublication.id
    },

    checkedOrShown() {
      return this.onlyPublication ? this.shown : this.checked;
    }
  },

  watch: {
    storyPublication(storyPub) {
      // Check here in case the async call to fetch story publications loads after mounting
      this.syncState(storyPub)
    }
  },

  mounted() {
    // Check here in case the async call to fetch story publications loads before mounting
    this.syncState(this.storyPublication);
  },

  methods: {
    syncState(storyPub) {
      // TODO: make sure this works
      // TODO: determine if we need to check subsites here
      if(this.onlyPublication && (storyPub.content_type_key || storyPub.story_template_id)) {
        this.shown = true;
      }
    },

    toggle() {
      const { id } = this.storyPublication

      this.updating = true

      if (id) {
        const story_publication = { id, story_id: this.storyId }

        this.$store.dispatch('storyPublications/destroy', { story_publication }).then(() => {
          this.fetchStoryPubs()
        })
      } else {
        const story_publication = { ...this.storyPublication, story_id: this.storyId }

        this.$store.dispatch('storyPublications/create', { story_publication }).then(() => {
          this.fetchStoryPubs()
        })
      }
    },

    fetchStoryPubs() {
      const { storyId } = this

      this.$store.dispatch('storyPublications/fetch', { storyId }).then(() => {
        this.updating = false
      })
    }
  }
}
</script>

<style scoped>
  .panel-heading label {
    position: relative;
    display: block;
  }

  .check-icon {
    position: relative;
    display: inline-block;
    margin: 0 0.5em 0 0;
  }

  h4 {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  h4 small {
    padding-left: 0.5em;
  }

  .loader-wrapper {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin: 0 0.5em 0 0;
  }

  .loader {
    width: 1em;
  }

  .row.only h4 {
    margin-top: 7px;
  }
</style>
