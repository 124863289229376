<template lang="pug">
.communityq-content-type-field
  select.form-control(v-model="selectedIdentifier")
    option
    option(
      v-for="contentType in contentTypes"
      :value="contentType.identifier"
      :key="contentType.id"
    )
      | {{ contentType.name }}
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  props: {
    publicationId: {
      required: true,
      type: Number
    },
    value: {
      type: String,
      required: false
    }
  },

  computed: {
    contentTypes: sync('communityqContentTypes/contentTypes'),

    selectedIdentifier: {
      get() { return this.value },
      // Emits the value to support usage with v-model. The change will then
      //flow back down through the value prop, so we don't need to set it here.
      set(val) { this.$emit('input', val); }
    }
  },

  mounted() {
    this.$store.dispatch('communityqContentTypes/fetch', this.publicationId);
  }
}
</script>
