<template lang="pug">
.form-group
  label.control-label Status
  select.form-control(v-model="onlinestatusId")
    option(value="") Select an Online Status
    option(v-for="onlineStatus in $store.getters['onlinestatuses/all']" :value="onlineStatus.id") {{ onlineStatus.title }}
</template>

<script>
  export default {
    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      }
    },

    data() {
      return {
        onlinestatusId: null
      }
    },

    watch: {
      value() {
        this.onlinestatusId = this.value
      },

      onlinestatusId() {
        // For component v-model support
        this.$emit('input', this.onlinestatusId);
      }
    },

    async mounted() {
      await this.$store.dispatch('onlinestatuses/fetch')
    }
  }
</script>
