<template lang="pug">
.modal.fade(tabindex="-1" data-backdrop="static")
  .modal-dialog(style="width: 550px")
    .modal-content
      form(v-on:submit.prevent="submit")
        .modal-header
          button.close(type="button" @click="hide")
            i.fa.fa-times
          h3 Online Go Live Date
        .modal-body
          p.text-center Choose a "go live" date for the web

          CommunityQPublishDate(
            v-model="pubTime"
            :when="when"
            @whenInput="when = $event"
          )

        .modal-footer
          button.btn.btn-link.text-muted(@click="hide" type="button" :disabled="submitting")
            | Cancel
          button.btn.btn-primary(type="submit" v-if="!submitting") Export to Web
          button.btn.btn-default(type="button" disabled v-else)
            i.fa.fa-circle-o-notch.fa-spin
            |
            |
            | Exporting to Web
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { isEmpty } from 'lodash'
  import CommunityQPublishDate from './publish_date.vue'

  export default {
    components: {
      CommunityQPublishDate
    },

    data() {
      return {
        submitting: false
      }
    },

    computed: {
      storyIds: sync('communityqPublishing/storyIds'),
      when: sync('communityqPublishing/when'),
      pubTime: sync('communityqPublishing/pubTime'),
    },

    watch: {
      storyIds(ids) {
        if(isEmpty(ids)) {
          $(this.$el).modal('hide')
        } else {
          $(this.$el).modal({show: true})
        }
      }
    },

    methods: {
      hide() {
        this.storyIds = []
      },

      submit() {
        this.submitting = true;

        this.$store.dispatch('communityqPublishing/submit').then(() => {
          this.submitting = false
        }).catch(() => {
          this.submitting = false
        })
      }
    }
  }
</script>
