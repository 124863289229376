<template lang="pug">
div
  section.batch-options.panel.panel-default(v-if="anySelectedStories")
    .panel-body
      .row
        .col-md-3
          .form-group
            label Pub date
            DateField(
              input-class="form-control"
              :disabled-dates="{to: new Date()}"
              placeholder="Don't Change"
              v-model="pubDate"
            )

        .col-md-3
          .form-group
            label Owner
            SelectInput(
              v-model="userId"
              blank-option-name="Don't Change"
              :options="userOptions"
            )

        .col-md-2
          .form-group
            label Print section
            SelectInput(
              v-model="sectionId"
              blank-option-name="Don't Change"
              :options="sectionOptions"
            )

        .col-md-2
          .form-group
            label Status
            SelectInput(
              v-model="squeueId"
              blank-option-name="Don't Change"
              :options="squeueOptions"
            )

        .col-md-2
          .form-group
            label Web category
            SelectInput(
              v-model="webCategoryId"
              blank-option-name="Don't Change"
              :options="webCategoryOptions"
            )

      .row
        .col-md-12.text-right
          a.btn.btn-default(:href="downloadLink")
            i.fa.fa-cloud-download
            |
            | Download
          |
          |
          a.btn.btn-default(v-on:click="openCommunityQPublishModal()")
            i.fa.fa-cloud-upload
            |
            | Export to Web
          |
          |
          button.btn.btn-primary(
            type="button"
            :disabled="!anyChanges"
            v-on:click="submit()"
            v-text="buttonTitle('Update')"
          )
</template>

<script>
  import DateField from '../../shared/date_field.vue'
  import axios from 'axios';
  import { sync } from 'vuex-pathify';
  import { mapGetters } from 'vuex';
  import SelectInput from '../../shared/select_input'

  export default {
    components: {
      DateField,
      SelectInput
    },

    computed: {
      ...sync('storyBatchOptions/options@*'),
      ...mapGetters(
        'storyBatchOptions', [
          'storyData',
          'anyChanges',
          'selectedStoriesCount',
          'anySelectedStories'
      ]),
      ...mapGetters('users', { userOptions: 'allAsObject' }),
      ...mapGetters('sections', { sectionOptions: 'enabledAsObject' }),
      ...mapGetters('squeues', { squeueOptions: 'enabledAsObject' }),
      ...mapGetters('webCategories', { webCategoryOptions: 'allAsObject' }),
      downloadLink() {
        return `/stories.zip?${jQuery.param({ids: this.selectedStoryIds})}`;
      }
    },

    mounted() {
      const { dispatch } = this.$store;

      dispatch('users/fetchOnce');
      dispatch('sections/fetchOnce');
      dispatch('squeues/fetchOnce');
      dispatch('webCategories/fetchOnce');
    },

    methods: {
      buttonTitle(action) {
        switch (this.selectedStoriesCount) {
          case 1: return `${action} Story`;
          case 2: return `${action} Both Stories`;
          default: return `${action} All ${this.selectedStoriesCount} Stories`;
        }
      },

      openCommunityQPublishModal() {
        this.$store.dispatch('communityqPublishing/setStoryIds', this.selectedStoryIds)
      },

      submit() {
        if (!this.anyChanges) {
          alert('Please select a change to at least one field');
        } else {
          const data = { ids: this.selectedStoryIds, story: this.storyData };

          axios.patch('/api/internal/stories/batch_update', data).then(() => {
            this.$emit('change');
          }).catch(err => {
            this.$store.dispatch('messages/addError', err);
          })
        }
      }
    }
  }
</script>
