<template lang="pug">
.col-sm-6.col-md-4.col-sg-3
  .thumbnail(:class="{ selected: selected }")
    template(v-if="isImage")
      ImageLoader(imgClass="thumb" :src="thumbUrl")
    template(v-else)
      .thumb.file.text-center.text-muted
        i.fa.fa-file-o
        .extension
          | .{{ url | fileExtension }}

    .caption
      span.stories.text-muted(v-if="stories.length > 0")
        i.fa.fa-newspaper-o

      span.filename.text-muted
        | {{ url | mediaFileName }}

    i.check.fa.fa-check-square(v-if="selected")
</template>

<script>
  import ImageLoader from '../shared/image_loader.vue'

  export default {
    components: {
      ImageLoader
    },

    props: {
      medium: {
        type: Object,
        required: true
      },

      selected: {
        type: Boolean
      }
    },

    computed: {
      url() {
        return this.medium.url;
      },

      thumbUrl() {
        return this.medium.large_thumb_url;
      },

      contentType() {
        return this.medium.content_type;
      },

      stories() {
        return this.medium.stories;
      },

      isImage() {
        return (this.contentType != null) && (this.contentType.split('/')[0] === 'image');
      }
    }
  }
</script>
